<template>
	<main class="layout-auth">
		<div class="layout-auth__wrapper">
			<div class="layout-auth__left">
				<div class="layout-auth__content">
					<div class="layout-auth__logo">
						<img
							class="layout-auth__logo-image"
							src="@/assets/images/login/logo.png"
							alt="Логотип" />
					</div>

					<h2 class="layout-auth__title">
						Добро пожаловать <br />
						на REXRENT!
					</h2>
				</div>

				<img
					class="layout-auth__left-img-bg"
					src="@/assets/images/login/car.png"
					alt="Автомобиль" />

				<Bubble
					width="453px"
					height="453px"
					right="-144px"
					top="-130px"
					:directions="120" />

				<Bubble
					class="layout-auth__big-bubble"
					width="1035px"
					height="1035px"
					top="318px"
					:left="$viewport.isLessThan('xxll') ? '-637px' : '-277px'"
					:color-grad="$viewport.isLessThan('xxll') ? '#ae1212 50%, #ff2b2b' : '#AE1212 0%, #FF2B2B 100%'" />
			</div>

			<div class="layout-auth__right">
				<slot></slot>
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
	const { $viewport } = useNuxtApp();
</script>

<style scoped lang="scss">
	@import "@/assets/scss/layouts/auth/styles";
</style>
